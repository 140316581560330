$blue: rgb(12, 127, 204);
$blue2: rgb(17, 109, 194);
$bluedark:#00529C;
$bluelight:  rgb(33, 138, 236);
$bluelight2: rgb(42, 170, 255);
$gray: #F2F2F2;
$graydark: #eaeae9;
$red: #da352a;
$green: #4ba834;
$white: #FFFFFF;
$black: #000000;
$dark-gradient: linear-gradient(180deg, rgb(9, 64, 112) 5%, rgba(0,82,156,1) 87%);
$dark-gradient2: linear-gradient(90deg, rgba(9,64,112,1) 0%, rgba(16,42,65,1) 100%);
$light-gradient: linear-gradient(190deg, rgba(1,82,156,1) 47%, rgba(17,109,194,1) 93%);
$light-gradient2: linear-gradient(0deg,rgb(12, 127, 204) 0%, rgb(100, 193, 255) 100%);
$drkblue:  rgba(16,42,65,1);
$fontPrimary: 'Montserrat';
$fontBold: '700';
$separator: 6rem;
$separatorTop: 4rem;
