.ticketDetails {
  .modal {
    height: 90%;
    overflow-y: scroll;
    @media screen and (max-width: 991px) {
      height: 80% !important;
    }
    .body {
      .spaceBetween {
        display: flex;
        justify-content: space-between;
        width: 100%;
        button {
          padding: 0;
        }
      }
      .title {
        margin: 0;
        flex: 1 1 100%;
        h2 {
          text-align: start;
          font-size: 1.25rem;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          font-weight: 500;
          line-height: 1.6;
          letter-spacing: 0.0075em;
          color: black;
        }
      }
      height: 400px;
      padding: 0 2rem;
    }
  }
}
