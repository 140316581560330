@import "./variable.scss";
@import "./index.scss";
@import "./normalize.scss";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap");

body,
button,
input,
optgroup,
select,
textarea {
  font-family: $fontPrimary;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  color: $bluedark;

  .react-ip-input input {
    box-sizing: inherit;
    font: inherit;
    color: black;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

h1 {
  color: $bluedark;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 2rem;
}

a {
  cursor: pointer;
}

.grid-box {
  display: grid;
  grid-gap: 2rem;
  align-items: center;

  &.grid-four {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &.grid-three {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.grid-two {
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }
}

.v-height {
  min-height: 100vh;
  height: 100%;
}

.background-gray {
  background: $gray;
}

.background-blue-dark {
  background: $bluedark;
}

img,
svg,
iframe {
  max-width: 100%;
}
.ajuda {
  .modal {
    height: 90% !important;
    width: 70% !important;
    div {
      margin-top: 25%;
      font-size: 25px;
      font-weight: bold;
    }
  }
}

.MuiMenu-paper {
  .MuiListItemIcon-root {
    min-width: 35px !important;
  }
  .actionText,
  a:link,
  a:visited {
    color: #000;
    text-decoration: none;
    &:hover {
      color: $blue;
    }
  }
}
input[type="text"]:disabled, select.MuiSelect-select.Mui-disabled, .MuiInputBase-input:disabled {
	cursor: not-allowed;
  color: #000;
}

.MuiInputBase-root.Mui-disabled, .MuiSelect-select.Mui-disabled {

  cursor: not-allowed !important;
  color: #000 !important;
}

.modalRedefinePassword {
  margin: auto;
  position: absolute;
  width: 800px;
  height: 275px;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  border-radius: 8px;
  background-color: white;
  outline: 0;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.09);

  text-align: center;

  @media screen and (max-width: 991px) {
    width: auto;
    margin-left: 2rem;
    margin-right: 2rem;
    height: 400px;
    form {
      font-size: 0.8rem;
      padding: 1rem 
    }
  }

  form {
    padding: 2rem 2rem 0 2rem;

    .eyepass {
      color: #8d8d8d;
      cursor: pointer;
      margin-top: 20px;
      :hover {
        color: $bluedark;
      }
    }
  }
}

.modal {
  margin: auto;
  position: absolute;
  width: 650px;
  height: 275px;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  border-radius: 4px;
  background-color: white;
  outline: 0;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.09);

  text-align: center;

  @media screen and (max-width: 991px) {
    width: auto;
    margin-left: 2rem;
    margin-right: 2rem;
    height: 370px !important;
    form {
      font-size: 0.8rem;
      padding: 1rem !important;
    }
  }

  form {
    padding: 2rem 2rem 0 2rem;

    .eyepass {
      color: #8d8d8d;
      cursor: pointer;
      margin-top: 20px;
      :hover {
        color: $bluedark;
      }
    }
  }
}
.oracle {
  margin-top: 3rem;
  height: 80px;
  @media screen and (max-height: 590px) {
    height: 50px !important;
  }
}

.login {
  .item-right {
    background-color: rgb(252, 252, 252) !important;
    .form {
      input {
        color: $blue !important;
        &:focus {
          border-color: $blue !important;
        }
      }

      button {
        background: $bluelight !important;

        &:hover {
          background: $bluelight2 !important;
        }
      }
      @media screen and (max-width: 991px) {
        padding: 0 2rem 0 2rem;
        margin: 0rem !important;
      }

      .esqueci {
        a {
          color: $bluelight !important;
          &:hover {
            color: $bluelight2 !important;
          }
        }
      }
    }
  }
}
.form {
  text-align: center;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 991px) {
    padding: 0 2rem 0 2rem;
    margin: 0rem !important;
  }

  input {
    width: 100%;
    background: transparent;
    border: 0;
    border-bottom: solid 2px $white;
    padding: 0.5rem 1rem;
    color: $white;
    margin: 1rem 0;
    outline: none;

    &::placeholder {
      color: $white;
    }

    &:focus {
      border-color: $blue;
    }
  }

  button {
    width: 100%;
    border: 0;
    border-radius: 0.5rem;
    height: 45px;
    background: $light-gradient2;
    color: $white;
    font-family: $fontPrimary;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.5rem;
    cursor: pointer;

    background: $light-gradient !important;

    &:hover {
      background: $blue !important;
    }
  }
  .esqueci {
    a {
      color: $bluedark !important;
      font-weight: bold;
      margin: 0 !important;

      &:hover {
        color: $blue !important;
        text-decoration: underline !important;
      }
    }
  }
}

.fixed-bottom,
.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 2;
}

.fixed-bottom {
  bottom: 0;
}

.fixed-top {
  top: 0;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignRight {
  min-width: 58px;
}

main {
  background-color: $gray;
  //min-height: 100vh;
  //height: 100%;
  margin: 61px 0 0 175px;
  padding: 2rem 3rem 0 3rem;
  transition: margin-left 0.3s;
  .filterField {
    display: flex;
    align-items: center;

    width: auto;
    padding-bottom: 3rem;
    padding-top: 1rem;
    margin: auto;

    .MuiFormControl-root,
    .autocompleteSearch {
      margin: 0 0.6rem;
    }
    .autocompleteSearch {
      width: 20%;

      button {
        margin-top: 0;
      }
    }
    .searchButton {
      width: 180px;
      height: 40px;
      margin: 0 1rem 0 1rem;
    }


    
    @media screen and (max-width: 1100px) {
      display: block;
      text-align: center;
      .MuiFormControl-root,
      .autocompleteSearch,
      button {
        margin: 0px;
      }
      .MuiFormControl-root {
        width: 80%;
      }
      .autocompleteSearch {
        width: 100%;
      }
      .searchButton {
        margin-top: 1rem;
      }
    }
  }
  .no-data {
    width: 100%;
    text-align: center;
    align-self: center;
    align-items: center;
    margin-bottom: 1rem;
    .no-data-svg {
      height: 140px;
      @media screen and (max-width: 700px) {
        height: auto;
      }
    }
    div {
      margin-top: 0.4rem;
      color: #929292;
      font-size: 0.9rem;
      @media screen and (max-width: 700px) {
        font-size: 0.7rem;
        margin: 0 1rem;
      }
    }
  }

  .cadastrar {
    .card {
      background: white !important;
      cursor: default;
      h1 {
        color: $bluedark;
      }
    }
  }

  form {
    .radioButton {
      text-align: center;
      align-self: center;
      align-items: center;

      .MuiTypography-body1 {
        color: black;
        font-size: 0.9rem;
      }
    }
  }

  @media screen and (max-width: 700px) {
    margin-left: 0;
    padding: 1rem 1rem 0 1rem;
  }
}

.app-container#compact-menu {
  main {
    margin-left: 100px;
  }
}

button.toggle {
  position: fixed;
  left: 140px;
  top: 20px;
  z-index: 3;
  background: none;
  border: none;
  outline: none;
  height: 25px;
  width: 30px;
  padding: 0;
  cursor: pointer;

  @media screen and (max-width: 560px) {
    left: auto;
    right: 20px;
  }

  i {
    display: block;
    height: 2px;
    position: absolute;
    top: 10px;
    width: 30px;
    background: #fff;
    right: 0px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      right: 0px;
      width: 30px;
      height: 2px;
      background: #fff;
      backface-visibility: hidden;
    }

    &:before {
      top: -10px;
    }

    &:after {
      bottom: -10px;
    }
  }
}
.homeUser {
  .card {
    background: $bluelight2;
    &:hover {
      background: $bluelight;
    }
  }
}
.card {
  background: $blue;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100%;
  word-wrap: break-word;
  background-clip: initial;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  cursor: pointer;

  .card-body {
    flex: 1 1 auto;
    padding: 1.5rem;

    h1 {
      color: white;
      text-transform: uppercase;
      margin: 0 0.5rem;
    }
    h2 {
      text-transform: uppercase;
      margin: 0 0.5rem;
    }
  }

  .card-grid {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;

      .grid-box {
        margin-top: 1rem;
      }
      img,
      svg {
        max-width: 200px;
      }
    }
    a {
      margin: 0.5rem;
    }

    h2 {
      margin-bottom: 0;
    }

    h3 {
      margin-top: 0;
    }
  }
  transition: linear 0.08s;
  &:hover {
    background: $bluedark;
  }
}

a.button,
button.button {
  padding: 0.5rem 1rem;
  border: 0;
  border-radius: 1rem;
  height: 45px;
  background: #008ed2;
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  margin: 0 0.5rem;

  &.blue {
    background: $blue;
  }

  &.blue-dark {
    background: $bluedark;
  }

  &:hover {
    background: $blue;
  }
}

.container-pass {
  display: flex;
  position: relative;
  align-items: center;

  .passMUI {
    top: -3.8rem;
    margin-right: 1rem;
  }
  span {
    color: #8d8d8d;
    position: absolute;
    right: 0;
    cursor: pointer;
    margin-top: 20px;
    :hover {
      color: $bluedark;
    }
  }
}

.app-container {
  .MuiIconButton-root {
    .delete {
      color: $red !important;
    }
    .ativar {
      color: $green !important;
    }
    .MuiSvgIcon-root {
      color: $blue;
    }
    &:hover {
      .delete {
        color: red !important;
      }
      .MuiSvgIcon-root {
        color: $bluedark;
      }
    }
  }

  .MuiTableCell-root {
    font-family: $fontPrimary !important;

    a {
      text-decoration: none;
    }

    button {
      margin: 0 0.3rem;
    }
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: $bluedark;
  }

  .MuiButton-containedPrimary {
    background-color: $blue;

    &.red-button {
      background-color: $blue;
    }

    &:hover {
      background-color: $bluedark;
    }
  }

  .MuiButton-containedSecondary {
    background-color: $blue;

    &.red-button {
      background-color: $blue;
    }

    &:hover {
      background-color: $bluedark;
    }
  }

  @media screen and (max-width: 991px) {
    .makeStyles-grid3-3,
    .makeStyles-grid-2 {
      display: block;
    }

    .makeStyles-grid3-3 > *,
    .makeStyles-grid-2 > * {
      width: 100%;

      .MuiOutlinedInput-root {
        display: block;
      }
    }
  }
}
